import React, { useContext, useEffect, useState } from "react";

import { useMutation } from "@apollo/client";
import { ErrorCodeEnum } from "@layout/modals/types";
import _uniqueId from "lodash/uniqueId";

import { Icon, IconSizeEnum, IconTypeEnum } from "@/components/common/icon";
import EmailInput from "@/components/common/Inputs/emailInput";
import UserContext from "@/contexts/User/UserContext";
import { getErrorCode } from "@/utils/apolloUtils";
import { trimAndLowerCase } from "@/utils/stringUtils";
import { Button, ButtonSizeEnum } from "@components/common/button";
import { CHANGE_EMAIL } from "@components/profile/account/graphql/CHANGE_EMAIL";
import { REQUEST_CONFIRM_EMAIL } from "@components/profile/account/graphql/REQUEST_CONFIRM_EMAIL";

import { EmailBlockProps } from "./types";
import { getChangeEmailErrorByCode } from "./utils";

import "../styles.scss";

const EmailBlock = ({ setShowAnimationWithConfetti }: EmailBlockProps) => {
  const { email, isEmailVerified } = useContext(UserContext);
  const [newEmail, setNewEmail] = useState(email || "");
  const [emailErrorText, setEmailErrorText] = useState<string | null>(null);
  const [errorCode, setErrorCode] = useState<number | null>(null);
  const [isEmailApproved, setIsEmailApproved] = useState(isEmailVerified);
  const [isEmailEditing, setIsEmailEditing] = useState(email && email.length === 0);
  const [isRequestedConfirmEmail, setIsRequestedConfirmEmail] = useState<boolean>(false);
  const [
    changeEmail,
    { error: changeEmailError },
  ] = useMutation(CHANGE_EMAIL);
  const [
    requestConfirmEmail,
    {
      data: requestConfirmEmailData,
      loading: requestConfirmEmailLoading,
      error: requestConfirmEmailError,
    },
  ] = useMutation(REQUEST_CONFIRM_EMAIL);
  const [inputUniqueId] = useState(_uniqueId());

  const changeEmailClick = () => {
    setIsEmailEditing(true);
  };

  const sendCheckCode = () => {
    requestConfirmEmail({ variables: { email: trimAndLowerCase(newEmail) } });
  };

  const handleSubmit = () => {
    if (emailErrorText) {
      return;
    }

    if (newEmail !== email) {
      setIsEmailApproved(false);
      setIsRequestedConfirmEmail(false);
      changeEmail({ variables: { email: trimAndLowerCase(newEmail) } });
    }
  };

  const endEmailEditingHandler = () => {
    handleSubmit();
    setShowAnimationWithConfetti(true);
    setIsEmailEditing(false);
  };

  useEffect(() => {
    if (errorCode === ErrorCodeEnum.StatusCodeEmailAlreadyUsed) {
      setNewEmail(email || "");
    }
  }, [email, errorCode]);

  useEffect(() => {
    if (changeEmailError) {
      setErrorCode(getErrorCode(changeEmailError));
    } else if (!requestConfirmEmailError) {
      setErrorCode(null);
    }
  }, [changeEmailError, requestConfirmEmailError]);

  useEffect(() => {
    if (requestConfirmEmailData) {
      setIsRequestedConfirmEmail(true);
    }

    if (requestConfirmEmailError) {
      setErrorCode(getErrorCode(requestConfirmEmailError));
    } else if (!changeEmailError) {
      setErrorCode(null);
    }
  }, [
    requestConfirmEmailData,
    requestConfirmEmailLoading,
    requestConfirmEmailError,
    changeEmailError,
  ]);

  useEffect(() => {
    if (email) {
      setNewEmail(email);
      setIsEmailEditing(false);
    } else {
      setIsEmailEditing(true);
    }
  }, [email]);

  useEffect(() => {
    setIsEmailApproved(isEmailVerified);
  }, [isEmailVerified]);

  return (
    <div className="account__group">
      <div className="input-group">
        <div className="input-group__item login-method">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className="h4" htmlFor={`email${inputUniqueId}`}>Email</label>
          {isEmailEditing ? (
            <>
              <EmailInput
                id={`email${inputUniqueId}`}
                initaiEmail={email || ""}
                onChange={(changedEmail, errorText) => {
                  setNewEmail(changedEmail);
                  setEmailErrorText(errorText);
                }}
                onSubmit={handleSubmit}
              />
              {newEmail && emailErrorText && (
                <div className="input-group__error">Введите e-mail в формате maria@mail.ru</div>
              )}
              <div className="input-group__text">Для отправки чеков и персональных промокодов</div>
              {newEmail && !emailErrorText && (
                <div className="login-method__button">
                  <Button
                    text="Сохранить"
                    size={ButtonSizeEnum.Middle}
                    onClick={endEmailEditingHandler}
                  />
                </div>
              )}
            </>
          ) : (
            <>
              <div className="login-method__email">
                {newEmail}
                &nbsp;&nbsp;
                <span className="login-method__button" onClick={changeEmailClick}>
                  Изменить
                </span>
              </div>
              {!!errorCode && <div className="input-group__error">{getChangeEmailErrorByCode(errorCode)}</div>}
              {!errorCode && isEmailApproved && (
                <div className="login-method__info login-method__info--valid">
                  <Icon type={IconTypeEnum.Tick} size={IconSizeEnum.Size20} />
                  Подтверждён
                </div>
              )}
              {email && !errorCode && !isEmailApproved && (
                <div className="login-method__info login-method__info--invalid">
                  <Icon type={IconTypeEnum.Error} size={IconSizeEnum.Size32} />
                  Нужно подтвердить e-mail
                </div>
              )}
              {!isEmailApproved
                && (isRequestedConfirmEmail ? (
                  <div className="login-method__info login-method__info--valid">
                    <Icon type={IconTypeEnum.Tick} size={IconSizeEnum.Size20} />
                    Письмо для подтверждения отправлено
                  </div>
                ) : (
                  <div className="login-method__button" onClick={sendCheckCode}>
                    Отправить письмо для подтверждения
                  </div>
                ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmailBlock;
