import React, { useState } from "react";

import _uniqueId from "lodash/uniqueId";

import { ToggleProps } from "./types";
import "./styles.scss";

const Toggle = (props: ToggleProps) => {
  const {
    isChecked, optionOn, optionOff, onChange,
  } = props;
  const [inputUniqueId] = useState(_uniqueId());

  return (
    <div className="toggle">
      <input
        type="checkbox"
        id={`toggle${inputUniqueId}`}
        checked={isChecked}
        onChange={() => {
          if (onChange) {
            onChange(!isChecked);
          }
        }}
      />
      <label htmlFor={`toggle${inputUniqueId}`}>
        <span>{optionOn}</span>
        <span>{optionOff}</span>
      </label>
    </div>
  );
};

export default Toggle;
