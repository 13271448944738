// todo: maybe unite with PHONE_SESSION_ERRORS
export const getChangePhoneErrorMessage = (errorCode: number): string => {
  switch (errorCode) {
    case 3: return `Произошла ошибка. Обновите страницу и\u00A0попробуйте ещё раз`; // StatusCodeAccessDenied
    case 24: return `Неправильный телефон`; // StatusCodeInvalidPhone
    case 31: return `Данный телефон не\u00A0поддерживается. Введите другой или\u00A0напишите в\u00A0Службу поддержки`; // StatusCodePhoneNotSupported
    case 51: return `Данный телефон занят. Введите другой или\u00A0напишите в\u00A0Службу поддержки`; // StatusCodePhoneAlreadyInUse
    default: return `Произошла ошибка. Попробуйте ещё\u00A0раз или\u00A0напишите в\u00A0Службу поддержки`;
  }
};

export const getRequestConfirmPhoneErrorMessage = (errorCode: number): string => {
  switch (errorCode) {
    case 3: return `Произошла ошибка. Обновите страницу и\u00A0попробуйте ещё раз`; // StatusCodeAccessDenied
    case 26: return `Слишком много запросов`; // StatusCodeTooManyRequests
    case 51: return `Данный телефон занят. Введите другой или\u00A0напишите в\u00A0Службу поддержки`; // StatusCodePhoneAlreadyInUse
    default: return `Произошла ошибка. Попробуйте ещё\u00A0раз или\u00A0напишите в\u00A0Службу поддержки`;
  }
};

export const getConfirmPhoneErrorMessage = (errorCode: number): string => {
  switch (errorCode) {
    case 3: return `Произошла ошибка. Обновите страницу и\u00A0попробуйте ещё раз`; // StatusCodeAccessDenied
    case 22: return `Неверный код, проверьте СМС или\u00A0получите новый`; // StatusCodeWrongCredentials
    case 28: return `Срок жизни кода истёк. Нажмите «Получить новый код»`; // StatusCodeExpiredCredentials
    case 29: return `Срок жизни кода истёк. Нажмите «Получить новый код»`; // StatusCodeCredentialsAlreadyUsed
    default: return `Произошла ошибка. Попробуйте ещё\u00A0раз или\u00A0напишите в\u00A0Службу поддержки`;
  }
};
