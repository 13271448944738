import { gql } from "@apollo/client";

export const GET_ACCOUNT_PAGE_DATA = gql`
  query getAccountPageData {
    getMyProfile {
      user {
        id
        mobileImage: image(size: 105) {
          onex
          twox
          threex
        }
        desktopImage: image(size: 176) {
          onex
          twox
          threex
        }
        name
        loginType
        dateOfBirth
        anonymous
        timezone
        gender
      }
      email
      isEmailVerified
      phone
      tmpPhone
      isExpert
    }
  }
`;
