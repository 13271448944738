import React, { useEffect, useState } from "react";

import { useMutation } from "@apollo/client";

import { Button, ButtonSizeEnum } from "@/components/common/button";
import { UserAvatar } from "@/components/common/userAvatar";
import { UserAvatarTypeEnum } from "@/components/common/userAvatar/types";

import { uploadAvatar as uploadAvatarType, uploadAvatarVariables } from "../graphql/__generated__/uploadAvatar";
import { UPLOAD_AVATAR_MUTATION } from "../graphql/UPLOAD_AVATAR_MUTATION";

import { AvatarProps } from "./types";

import "./styles.scss";

const AvatarBlock = ({
  mobileImage,
  desktopImage,
  name,
  userId,
  setShowAnimationWithConfetti,
}: AvatarProps) => {
  const [currentMobileImage, setCurrentMobileImage] = useState(mobileImage ?? "");
  const [currentDesktopImage, setCurrentDesktopImage] = useState(desktopImage ?? "");
  const [uploadAvatar, { data, loading, error }] = useMutation<
    uploadAvatarType,
    uploadAvatarVariables
  >(
    UPLOAD_AVATAR_MUTATION,
  );
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const handleClick = () => {
    hiddenFileInput?.current?.click();
  };

  const handleAvatarUpload = (e: any) => {
    if (e.target.validity.valid) {
      uploadAvatar({ variables: { file: e.target.files[0] } });
    }
  };

  useEffect(() => {
    if (data && !loading && !error) {
      // Backend doesn't store object with onex, twox, threex
      // So we use field "small"
      setShowAnimationWithConfetti(true);
      setCurrentMobileImage({
        onex: data.uploadAvatar.small,
        twox: data.uploadAvatar.small,
        threex: data.uploadAvatar.small,
      });
      setCurrentDesktopImage({
        onex: data.uploadAvatar.small,
        twox: data.uploadAvatar.small,
        threex: data.uploadAvatar.small,
      });
    }
  }, [data, loading, error]);

  return (
    <div className="account__group">
      <div className="input-group account-avatar__container">
        <h3 className="account-avatar__header">Фотография профиля</h3>
        <div className="account-avatar">
          <UserAvatar
            mobileImage={{
              onex: currentMobileImage.onex,
              twox: currentMobileImage.twox,
              threex: currentMobileImage.threex ?? currentMobileImage.twox,
            }}
            desktopImage={{
              onex: currentDesktopImage.onex,
              twox: currentDesktopImage.twox,
              threex: currentDesktopImage.threex ?? currentMobileImage.twox,
            }}
            name={name ?? ""}
            userId={userId}
            type={UserAvatarTypeEnum.Profile}
          />
          <div>
            <input
              type="file"
              name="avatar"
              id="avatar"
              onChange={handleAvatarUpload}
              ref={hiddenFileInput}
              style={{ display: "none" }}
            />
            <Button
              text="Загрузить фото"
              size={ButtonSizeEnum.Small}
              onClick={handleClick}
              isLoading={loading}
              className="account-avatar__button"
            />
            <div className="account-avatar__hynt">PNG, JPG или&nbsp;GIF</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AvatarBlock;
