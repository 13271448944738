import { UserLoginType } from "@/autoGeneratedGlobalTypes";
import { IconTypeEnum } from "@/components/common/icon";
import { ErrorCodeEnum } from "@/components/layout/modals/types";

export const socialToIconType = (social: UserLoginType) => {
  switch (social) {
    case UserLoginType.facebook:
      return IconTypeEnum.Facebook;
    case UserLoginType.google:
      return IconTypeEnum.Google;
    case UserLoginType.vk:
      return IconTypeEnum.Vk;
    default:
      return UserLoginType.google;
  }
};

export const getChangeEmailErrorByCode = (errorCode: number): string => {
  switch (errorCode) {
    case ErrorCodeEnum.StatusCodeAccessDenied: // токен не валидный
      return `Неверный код`;
    case ErrorCodeEnum.StatusCodeBadRequest: // новый адрес почты не понравился бэку
      return `Переданы некорректные данные`;
    case ErrorCodeEnum.StatusCodeNoSuchAccount: // каким-то образом акка или профиля нет
      return `Аккаунта с\u00A0такими данными не\u00A0найдено`;
    case ErrorCodeEnum.StatusCodeEmailAlreadyUsed: // новый адрес почты кем-то занят;
      return `Аккаунт с\u00A0таким e-mail уже есть, попробуйте ввести другой`;
    case ErrorCodeEnum.NoAlternativeAuthorizationMethod: // у пользователя не привязан номер
      return `Сначала нужно добавить телефон, чтобы не\u00A0потерять доступ к\u00A0аккаунту`;
    default:
      return `Произошла ошибка. Попробуйте ещё\u00A0раз или\u00A0напишите в\u00A0Службу поддержки`;
  }
};
