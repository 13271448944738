import React, { useContext, useEffect, useState } from "react";

import { useMutation } from "@apollo/client";
import _uniqueId from "lodash/uniqueId";

import { Icon, IconSizeEnum, IconTypeEnum } from "@/components/common/icon";
import PhoneInput from "@/components/common/Inputs/phoneInput";
import UserContext from "@/contexts/User/UserContext";
import { getErrorCode } from "@/utils/apolloUtils";
import { trimAndLowerCase } from "@/utils/stringUtils";
import { Button, ButtonSizeEnum } from "@components/common/button";
import { changePhone as changePhoneType, changePhoneVariables } from "@components/profile/account/graphql/__generated__/changePhone";
import { CHANGE_PHONE } from "@components/profile/account/graphql/CHANGE_PHONE";

import "../styles.scss";
import ChangePhoneModals from "./changePhoneModals";
import { PhoneBlockProps } from "./types";
import { getChangePhoneErrorMessage } from "./utils";

const PhoneBlock = ({ refetchAccountPageData, setShowAnimationWithConfetti }: PhoneBlockProps) => {
  const { phone, tmpPhone } = useContext(UserContext);
  const [newPhone, setNewPhone] = useState("");
  const [phoneErrorText, setPhoneErrorText] = useState<string | null>(null);
  const [isPhoneUnconfirmed, setIsPhoneUnconfirmed] = useState<boolean>(true);
  const [isPhoneEditing, setIsPhoneEditing] = useState(false);
  const [isModalShown, setIsModalShown] = useState<boolean>(false);
  const [
    changePhone,
    { data: changePhoneData, error: changePhoneError, loading: changePhoneLoading },
  ] = useMutation<changePhoneType, changePhoneVariables>(CHANGE_PHONE);

  const [inputUniqueId] = useState(_uniqueId());

  useEffect(() => {
    setIsPhoneEditing(!phone && !tmpPhone);
    setIsPhoneUnconfirmed(!phone);
  }, [phone, tmpPhone]);

  const changePhoneClick = () => {
    setIsPhoneEditing(true);
  };

  const handleSubmit = () => {
    if (phoneErrorText) {
      return;
    }

    if (newPhone !== phone) {
      changePhone({ variables: { newNumber: trimAndLowerCase(newPhone) } });
    }
  };

  useEffect(() => {
    if (changePhoneData && !changePhoneError && !changePhoneLoading) {
      window.carrotquest.identify({ $phone: trimAndLowerCase(newPhone) });
      setIsModalShown(true);
    }
  }, [changePhoneData, changePhoneError, changePhoneLoading, newPhone]);

  const endPhoneEditingHandler = () => {
    handleSubmit();
  };

  return (
    <div className="account__group">
      <div className="input-group">
        <div className="input-group__item login-method">
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className="h4" htmlFor={`phone${inputUniqueId}`}>Телефон</label>
          {isPhoneEditing ? (
            <>
              <PhoneInput
                id={`phone${inputUniqueId}`}
                phoneInitial={phone || tmpPhone || ""}
                setPrevValueCallback={() => {}}
                onChange={(changedPhone, errorText) => {
                  setNewPhone(changedPhone);
                  setPhoneErrorText(errorText);
                }}
                onSubmit={handleSubmit}
              />
              {newPhone && phoneErrorText && (
                <div className="input-group__error">{phoneErrorText}</div>
              )}
              {!!changePhoneError && (
                <div className="input-group__error">
                  {getChangePhoneErrorMessage(getErrorCode(changePhoneError))}
                </div>
              )}
              <div className="input-group__text">Отправим СМС с кодом подтверждения</div>
              <div className="login-method__button">
                <Button
                  text="Сохранить"
                  size={ButtonSizeEnum.Middle}
                  onClick={endPhoneEditingHandler}
                  disabled={!newPhone
                    || phone === newPhone
                    || !!phoneErrorText
                    || changePhoneLoading}
                />
              </div>
            </>
          ) : (
            <>
              <div className="login-method__phone">
                {phone || tmpPhone}
                &nbsp;&nbsp;
                <span
                  className="login-method__button"
                  onClick={changePhoneClick}
                  onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) =>
                    event.code === "Enter" && changePhoneClick()}
                  role="button"
                  tabIndex={-1}
                >
                  Изменить
                </span>
              </div>
              {!!changePhoneError && (
                <div className="input-group__error">
                  {getChangePhoneErrorMessage(getErrorCode(changePhoneError))}
                </div>
              )}
              {!changePhoneError && !isPhoneUnconfirmed && (
                <div className="login-method__info login-method__info--valid">
                  <Icon type={IconTypeEnum.Tick} size={IconSizeEnum.Size20} />
                  Подтверждён
                </div>
              )}
              {!changePhoneError && isPhoneUnconfirmed && (
                <div className="login-method__info login-method__info--invalid">
                  <Icon type={IconTypeEnum.Error} size={IconSizeEnum.Size32} />
                  Нужно подтвердить номер
                </div>
              )}
              {!phone && !isPhoneUnconfirmed && (
                <div className="login-method__button">
                  Отправим СМС с кодом для подтверждения
                </div>
              )}
              {isPhoneUnconfirmed && (
                <div
                  className="login-method__button"
                  onClick={() => {
                    setShowAnimationWithConfetti(true);
                    changePhone({ variables: { newNumber: trimAndLowerCase(tmpPhone) } });
                  }}
                >
                  Отправить СМС для подтверждения
                </div>
              )}
            </>
          )}
          {isModalShown
            && (
            <ChangePhoneModals
              setShowAnimationWithConfetti={setShowAnimationWithConfetti}
              phone={newPhone || tmpPhone || ""}
              refetchAccountPageData={refetchAccountPageData}
              closeCallback={() =>
                setIsPhoneEditing(false)}
            />
            )}
        </div>
      </div>
    </div>
  );
};

export default PhoneBlock;
