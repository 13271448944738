import React, { useContext, useEffect, useState } from "react";

import { useMutation } from "@apollo/client";
import { convertDateForBack, convertDateForFront } from "@layout/modals/utils";
import _uniqueId from "lodash/uniqueId";

import { Gender } from "@/autoGeneratedGlobalTypes";
import { Button, ButtonSizeEnum } from "@/components/common/button";
import BirthDate from "@/components/common/Inputs/BirthDate";
import Toggle from "@/components/common/toggle";
import UserContext from "@/contexts/User/UserContext";
import {
  fillQuestionnaireFromProfile,
  fillQuestionnaireFromProfileVariables,
} from "@components/profile/account/graphql/__generated__/fillQuestionnaireFromProfile";
import { SEND_PERSONAL_DATA } from "@components/profile/account/graphql/SEND_PERSONAL_DATA";

import { PersonalProps } from "./types";

const Personal = ({ userData, setShowAnimationWithConfetti }: PersonalProps) => {
  const {
    setName, setAnonymous, setDateOfBirth, setGender, setTimezone,
  } = useContext(UserContext);

  const {
    name, dateOfBirth, anonymous, timezone, gender,
  } = userData;
  const [editingName, setEditingName] = useState(name || "");
  const [editingAnonymous, setEditingAnonymous] = useState(anonymous || false);
  const [editingBirthDate, setEditingBirthDate] = useState(convertDateForFront(dateOfBirth) || "");
  const [editingBirthDateErrorText, setEditingBirthDateErrorText] = useState<string|null>(null);
  const [
    editingGender,
    setEditingGender,
  ] = useState(gender === Gender.MALE); // true/false: MALE/FEMALE
  const [editingTimezone, setEditingTimezone] = useState(timezone || "");
  const [inputUniqueId] = useState(_uniqueId());

  const [sendPersonalData, { data, loading, error }] = useMutation<
    fillQuestionnaireFromProfile,
    fillQuestionnaireFromProfileVariables
  >(SEND_PERSONAL_DATA);

  const setDateCallback = (value: string, errorText: string|null) => {
    setEditingBirthDate(value);
    setEditingBirthDateErrorText(errorText);
  };

  const handleSave = () => {
    if (editingBirthDateErrorText) {
      return;
    }
    sendPersonalData({
      variables: {
        name: editingName,
        anonymous: editingAnonymous,
        dateOfBirth: convertDateForBack(editingBirthDate),
        gender: editingGender ? Gender.MALE : Gender.FEMALE,
        timezone: editingTimezone,
      },
    });
    setShowAnimationWithConfetti(true);
  };

  useEffect(() => {
    if (data && !loading && !error) {
      setName(editingName);
      setAnonymous(editingAnonymous);
      setDateOfBirth(editingBirthDate);
      setGender(editingGender ? Gender.MALE : Gender.FEMALE);
      setTimezone(editingTimezone);
    }

    if (error) {
      setEditingName(name || "");
      setEditingAnonymous(anonymous);
      setEditingBirthDate(dateOfBirth || "");
      setEditingGender(gender === Gender.MALE);
      setEditingTimezone(timezone || "");
    }
  }, [
    anonymous,
    data,
    dateOfBirth,
    editingAnonymous,
    editingBirthDate,
    editingGender,
    editingName,
    editingTimezone,
    error,
    gender,
    loading,
    name,
    setAnonymous,
    setDateOfBirth,
    setGender,
    setName,
    setTimezone,
    timezone,
  ]);

  return (
    <div className="account__group">
      <div className="input-group">
        <label className="h4" htmlFor={`name${inputUniqueId}`}>
          Как вас зовут?
        </label>
        <input
          type="text"
          value={editingName}
          onChange={(e) =>
            setEditingName(e.target.value)}
          id={`name${inputUniqueId}`}
          autoFocus
        />

        <div className="input-group__checkbox">
          <input
            type="checkbox"
            id={`anonymous${inputUniqueId}`}
            checked={editingAnonymous}
            onChange={() =>
              setEditingAnonymous((prevVal: boolean) =>
                !prevVal)}
          />
          <label htmlFor={`anonymous${inputUniqueId}`}>Сохранить анонимность в отзывах</label>
        </div>
      </div>

      <div className="input-group">
        <div className="input-group__columns">
          <div className="input-group__item">
            <label className="h4" htmlFor={`date${inputUniqueId}`}>
              Дата рождения
            </label>
            <BirthDate
              id={`date${inputUniqueId}`}
              setDateCallback={setDateCallback}
              initialValue={editingBirthDate}
            />
            <div className="input-group__error">{editingBirthDateErrorText}</div>
          </div>
          <div className="input-group__item input-group__item--gender">
            <label className="h4">Пол</label>
            <Toggle
              isChecked={!!editingGender}
              optionOff="Ж"
              optionOn="М"
              onChange={(value) =>
                setEditingGender(value)}
            />
          </div>
        </div>
        <div className="input-group__text">
          Для вопросов совместимости с&nbsp;партнёром и&nbsp;анализа личности
        </div>
      </div>
      <div className="account__button">
        <Button
          text="Сохранить"
          size={ButtonSizeEnum.Middle}
          onClick={handleSave}
          isLoading={loading}
        />
      </div>
    </div>
  );
};

export default Personal;
