import React, { useContext, useEffect } from "react";

import { useLocation } from "@reach/router";
import { Link } from "gatsby";

import { Icon, IconSizeEnum } from "@/components/common/icon";
import GlobalContext from "@/contexts/Global/GlobalContext";
import UserContext from "@/contexts/User/UserContext";
import { marketingTrackFlocktoryExchange, marketingTrackGet4clickExchange } from "@/marketing/utils";
import { isBrowser } from "@/utils/env";

import { MENU_ITEMS } from "./constants";
import { ProfileLayoutProps } from "./types";

import "./styles.scss";

const ProfileLayout = ({ children }: ProfileLayoutProps) => {
  const location = useLocation();
  const { email } = useContext(UserContext);
  const { isExpert } = useContext(GlobalContext);

  useEffect(() => {
    if (location?.state?.showBannerFromFlocktory && !isExpert && email !== undefined) {
      marketingTrackGet4clickExchange(email || "");
      marketingTrackFlocktoryExchange(email || "xname@flocktory.com");
    }
  }, [email, isExpert, location?.state?.showBannerFromFlocktory]);

  return (
    <div className="profile">
      <aside className="menu-left">
        {isBrowser()
          ? MENU_ITEMS.map((item) =>
            (
              <Link to={item.link} className={`menu-left__item ${item.subTitle && "menu-left__position-icons-top"}`} activeClassName="active" key={item.link}>
                <Icon type={item.icon} size={IconSizeEnum.Size32} />
                <div>
                  <span className="menu-left__text">
                    {item.text}
                    {item.indicator && <div className="menu-left__indicator-link" />}
                  </span>
                  {item.subTitle && <div className="menu-left__subtitle">{item.subTitle}</div>}
                </div>
              </Link>
            ))
          : null}
      </aside>
      <div className="profile__content">{children}</div>
    </div>
  );
};

export default ProfileLayout;
