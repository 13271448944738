import * as React from "react";

import Account from "@/components/profile/account";
import ProfileLayout from "@/components/profile/profileLayout";
import { isBrowser } from "@/utils/env";

import "@/pages/common.scss";

const AccountPage = () => {
  if (!isBrowser()) {
    return null;
  }

  return (
    <ProfileLayout>
      <Account />
    </ProfileLayout>
  );
};

export default AccountPage;
