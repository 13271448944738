import React, { useEffect, useState } from "react";

import { useMutation } from "@apollo/client";
import { setAuthParamToURL } from "@layout/modals/utils";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";

import DigitCodeInput from "@/components/common/Inputs/digitCodeInput";
import { getInitiateCodeState, isCodeLengthValid } from "@/components/common/Inputs/digitCodeInput/utils";
import Modal from "@/components/common/modal";
import Timer from "@/components/common/Timer";
import { UrlParamKeyEnum } from "@/components/constants";
import SupportModal from "@/components/modals/supportModal";
import { confirmPhone as confirmPhoneType } from "@/components/profile/account/graphql/__generated__/confirmPhone";
import { CONFIRM_PHONE } from "@/components/profile/account/graphql/CONFIRM_PHONE";
import { REQUEST_CONFIRM_PHONE } from "@/components/profile/account/graphql/REQUEST_CONFIRM_PHONE";
import { getErrorCode } from "@/utils/apolloUtils";
import { SEND_OTP_CODE_RETRY_DELAY } from "@components/constants";
import { requestConfirmPhone as requestConfirmPhoneType } from "@components/profile/account/graphql/__generated__/requestConfirmPhone";

import { getConfirmPhoneErrorMessage, getRequestConfirmPhoneErrorMessage } from "../utils";

import { ChangePhoneModalEnum, ChangePhoneModalsProps } from "./types";
import "./styles.scss";

const ChangePhoneModals = ({ phone, refetchAccountPageData, closeCallback, setShowAnimationWithConfetti,
}: ChangePhoneModalsProps) => {
  const { search } = useLocation();
  const [
    formType,
    setFormType,
  ] = useState<ChangePhoneModalEnum | null>(null);
  const [code, setCode] = useState<string>(getInitiateCodeState());
  const [isUpdateCodeLinkShown, setIsUpdateCodeLinkShown] = useState(false);

  const [
    requestConfirmPhone,
    {
      error: requestConfirmPhoneError,
    },
  ] = useMutation<requestConfirmPhoneType>(REQUEST_CONFIRM_PHONE);

  const [
    confirmPhone,
    {
      data: confirmPhoneData,
      loading: confirmPhoneLoading,
      error: confirmPhoneError,
    },
  ] = useMutation<confirmPhoneType>(CONFIRM_PHONE);

  useEffect(() => {
    setAuthParamToURL(window.location, ChangePhoneModalEnum.Confirm, UrlParamKeyEnum.AccountModal);
  }, []);

  useEffect(() => {
    requestConfirmPhone({ variables: { phone } });
  }, [phone, requestConfirmPhone]);

  const helpClickHandler = () => {
    setAuthParamToURL(window.location, ChangePhoneModalEnum.Support, UrlParamKeyEnum.AccountModal);
  };

  // todo: maybe move this logic to util and reuse in authorization modals logic
  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const currentFormType = queryParams.get(UrlParamKeyEnum.AccountModal);
    const isCurrentTypeValid = Object.values(ChangePhoneModalEnum)
      .includes(currentFormType as ChangePhoneModalEnum);

    if (currentFormType && isCurrentTypeValid) {
      setFormType(currentFormType as ChangePhoneModalEnum);
    } else if (!currentFormType) {
      setFormType(null);
    }
  }, [search, setFormType]);

  useEffect(() => {
    if (!isCodeLengthValid(code)) {
      return;
    }
    confirmPhone({ variables: { code } });
  }, [code, confirmPhone]);

  useEffect(() => {
    if (confirmPhoneData && !confirmPhoneLoading && !confirmPhoneError) {
      setShowAnimationWithConfetti(true);
      navigate(-1);
      refetchAccountPageData();
    }
  }, [confirmPhoneData, confirmPhoneError, confirmPhoneLoading, refetchAccountPageData, setShowAnimationWithConfetti]);

  const checkTimer = (value: number) => {
    if (!isUpdateCodeLinkShown && value >= 0) {
      setIsUpdateCodeLinkShown(true);
    }
  };

  switch (formType) {
    case ChangePhoneModalEnum.Confirm:
      return (
        <Modal
          urlParamKey={UrlParamKeyEnum.AccountModal}
          header="Код из СМС"
          subtitle="Введите код, чтобы привязать номер телефона"
          isBackButtonShown
          formCloseCallback={closeCallback}
        >
          <div className="change-phone-modals">
            <DigitCodeInput
              code={code}
              setCode={setCode}
              disabled={confirmPhoneLoading}
            />
            {requestConfirmPhoneError
              && (
              <div className="change-phone-modals__error">
                {getRequestConfirmPhoneErrorMessage(
                  getErrorCode(requestConfirmPhoneError),
                )}
              </div>
              )}
            {confirmPhoneError
              && (
              <div className="change-phone-modals__error">
                {getConfirmPhoneErrorMessage(getErrorCode(confirmPhoneError))}
              </div>
              )}
            {" "}
            <div className="change-phone-modals__text">
              {`Код отправлен на ${phone} `}
              <b
                onClick={() =>
                  navigate(-1)}
                className="change-phone-modals__link"
              >
                Изменить
              </b>
            </div>
            <div className="change-phone-modals__text">
              {isUpdateCodeLinkShown ? (
                <b
                  onClick={() => {
                    setIsUpdateCodeLinkShown(false);
                    requestConfirmPhone({ variables: { phone } });
                  }}
                  className="change-phone-modals__link"
                >
                  Получить новый код
                </b>
              ) : (
                <>
                  Получить новый код можно через&nbsp;
                  <Timer
                    timestamp={Date.now() + SEND_OTP_CODE_RETRY_DELAY}
                    valueCallback={checkTimer}
                  />
                </>
              )}
            </div>
            <div className="change-phone-modals__text">
              <b onClick={helpClickHandler} className="change-phone-modals__link">
                Проблемы с получением кода?
              </b>
            </div>
          </div>
        </Modal>
      );
    case ChangePhoneModalEnum.Support:
      return <SupportModal urlParamKey={UrlParamKeyEnum.AccountModal} />;
    default: return null;
  }
};

export default ChangePhoneModals;
