import { gql } from "@apollo/client";

export const UPLOAD_AVATAR_MUTATION = gql`
  mutation uploadAvatar($file: Upload!) {
    uploadAvatar(file: $file) {
      small
      large
    }
  }
`;
