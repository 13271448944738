export enum ChangePhoneModalEnum {
  Confirm = "confirm_phone",
  Support = "support"
}

export type ChangePhoneModalsProps = {
  phone: string;
  refetchAccountPageData: () => void;
  closeCallback: () => void;
  setShowAnimationWithConfetti: (showConfetti: boolean) => void
}
